<!-- For use with CtCard -->
<template>
  <div class="ct-card-deck">
    <ul class="cards mt-5">
      <slot />
    </ul>
  </div>
</template>

<script>

export default {
  name: 'CtCardDeck',
}
</script>

<style lang="scss">
.ct-card-deck {
  margin: auto;
  ul.cards {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 38.75em;
    padding: unset !important;
    margin: auto;
    @media only screen and (max-width: 768px) {
      width: unset;
      max-height: unset;
    }
    .card .entity-type-input:focus {
      outline: auto !important;
      -webkit-appearance: auto !important;
    }
  }
}
.card {
  padding: 1.25em 0 !important;
}
</style>
